export default [
  {
    key: "created_by",
    label: "Employee",
    tdClass: "pt-1",

  },
  {
    key: "for_departament",
    label: "For Department",
    thClass: "text-center",
    tdClass: "text-center ",
    sortable: true,
  },
  {
    key: "code",
    thClass: "text-center",
    tdClass: "text-center pt-2 ",
  },
  {
    key: "type_request",
    label: "type",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    key: "title",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    key: "description",
    thStyle: { width: "20%" },
    tdClass: "pt-2",
  },
  {
    key: "status",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    key: "send_date",
    label: "CREATED DATE",
    thClass: "text-center",
    tdClass: "text-center pt-2",
    sortable: true,
  },
  {
    key: "survey",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    key: "tracking",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },

  {
    key: "actions",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
];
