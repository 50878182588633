export default [
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Status",
        model: null,
        options: [
          { value: 1, label: "Draft" },
          { value: 2, label: "Pending" },
          { value: 3, label: "In Revision" },
          { value: 4, label: "Resolved" },
          { value: 5, label: "Rejected" },
        ],
        reduce: "value",
        selectText: "label",
        cols: 6,
      },
      {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Departament",
        model: null,
        options: [
          { value: 19, label: "Logistic" },
          { value: 17, label: "Human Talent" },
        ],
        reduce: "value",
        selectText: "label",
        cols: 6,
      },
      {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Origin Departament",
        model: null,
        options: [],
        reduce: "id",
        selectText: "name",
        cols: 12,
      },
    ];
    
