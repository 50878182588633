<template>
  <b-card no-body class="mb-0 border-0">
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filtersSection"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refGridEmployeeClaims'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refGridEmployeeClaims"
          small
          :items="myProvider"
          :fields="fieldsUser"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :tbody-tr-class="returnClass"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(created_by)="data">
            <span>{{ data.item.created_by }}</span>
            <br />
            <b-badge variant="secondary">{{
              originModuleName(data.item.origin_module_name)
            }}</b-badge>
          </template>
          <template #cell(for_departament)="data">
            <div class="d-flex justify-content-center">
              <div>
                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 6px"
                >
                  <b-img
                    :src="
                      getProgramLogo(capitalizeNames(data.item.for_departament))
                    "
                    thumbnail
                    fluid
                    style="max-width: 40px; max-height: 40px"
                  />
                </div>
                <small>{{ capitalizeNames(data.item.for_departament) }}</small>
              </div>
            </div>
          </template>
          <template #cell(title)="data">
            <change-sms v-if="data.item.title" :SmsData="data.item.title" />
            <p v-else class="text-center">-</p>
          </template>
          <template #cell(description)="data">
            <change-sms
              v-if="data.item.description"
              :SmsData="data.item.description"
            />
            <p v-else class="text-center">-</p>
          </template>
          <template #cell(tracking)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="15"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                class="mr-50 cursor-pointer"
                v-b-tooltip.hover.top="'Tracking'"
                @click="
                  openTracking(
                    data.item.claim_request_id,
                    data.item.hidde_status_tracking
                  )
                "
              />
            </div>
          </template>
          <template #cell(survey)="data">
            <div class="d-flex justify-content-center">
              <b-badge :class="data.item.survey_rating ? 'bg-light-warning' : 'bg-light-secondary'" pill>
              <feather-icon
                v-if="[4, 5].includes(data.item.status)"
                icon="StarIcon"
                size="18"
                :class="data.item.survey_rating ? 'text-warning' : 'text-muted'"
                class="cursor-pointer"
                @click="openSurvey(data.item)"
              />
            </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-around">
              <b-badge
                class="text-center w-100"
                :class="colorStatus(data.item.status)[1]"
                pill
              >
                {{ colorStatus(data.item.status)[0] }}
              </b-badge>
            </div>
          </template>
          <template #cell(send_date)="data">
            <span> {{ data.item.send_date | myGlobalDay }} </span>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.status === 1"
                icon="EditIcon"
                size="20"
                class="text-warning cursor-pointer mr-1"
                style="margin-top: 6px"
                @click="openCreateEmployeeClaim(data.item)"
              />

              <feather-icon
                icon="EyeIcon"
                size="20"
                class="text-info cursor-pointer mr-1"
                style="margin-top: 6px"
                @click="openViewClaims(data.item)"
              />

              <feather-icon
                v-if="data.item.status != 1"
                icon="MessageCircleIcon"
                size="20"
                style="margin-top: 6px"
                class="text-info cursor-pointer mr-1"
                @click="openAttendClaimModal(data.item.claim_request_id)"
              />
            </div>
          </template>
        </b-table>
        <ModalTrackingEmployeeClaims
          v-if="claim.status"
          :claim="claim"
          @hiddenModal="claim.status = false"
        />

        <ModalAttendClaim
          v-if="showModalAttendClaim"
          :claim-id="claimId"
          @close="closeModalAttendClaim"
          @refreshTable="refreshTable"
        />
        <modal-view-client
          :id_claim="id_claim"
          v-if="openModalViewClient"
          @close="openModalViewClient = false"
        />
        <modal-survey-emoji-card
          v-if="openSurveyEmojiCard"
          @close="closeSurveyEmojiCard"
          :claim="claim"
        />
        <ModalCreateEmployeeClaim
          v-if="showModalCreateEmployeeClaim"
          @close="showModalCreateEmployeeClaim = false"
          :load-draft-on-mount="true"
          :draftId="draftId"
        />
      </template>
    </filter-slot>
  </b-card>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import fields from "@/views/commons/employee-claims/data/employee-claims.fields.js";
import filters from "@/views/commons/employee-claims/data/employee-claims.filters.js";
import EmployeeClaimsService from "@/views/commons/employee-claims/services/employee-claims.service";
import ModalTrackingEmployeeClaims from "@/views/commons/employee-claims/components/modal/TrackingEmployeeClaims.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
import ModalAttendClaim from "@/views/commons/employee-claims/components/modal/ModalAttendEmployeeClaim.vue";
import ModalViewClient from "@/views/commons/employee-claims/components/modal/ModalViewClaims.vue";
import ModalSurveyEmojiCard from "@/views/commons/employee-claims/components/modal/ModalSurveyEmojiCard.vue";
import ModalCreateEmployeeClaim from "@/views/commons/employee-claims/components/modal/ModalCreateEmployeeClaim.vue";

export default {
  components: {
    ChangeSms,
    ModalTrackingEmployeeClaims,
    ModalAttendClaim,
    ModalViewClient,
    ModalSurveyEmojiCard,
    ModalCreateEmployeeClaim,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      startPage: null,
      endPage: null,
      notificationAppModalOn: false,
      toPage: null,
      fields: fields,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      filters: filters,
      claim: { id: 0, status: false },
      showModalAttendClaim: false,
      claimId: null,
      dataProvider: [],
      id_claim: null,
      openModalViewClient: false,
      openSurveyEmojiCard: false,
      showModalCreateEmployeeClaim: false,
      draftId: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      employeeClaimsStore: "EmployeeClaimsStore/G_UPDATE_TABLE_EMPLOYEE_CLAIMS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fieldsUser() {
      let newfields = [];
      if (this.isSupervisor || this.isChief || this.allClaims) {
        newfields = this.fields;
      } else {
        newfields = this.fields.filter((item) => item.key != "created_by");
      }
      return newfields;
    },
    filtersSection() {
      let newfilters = [];

      if (this.allClaims) {
        newfilters = this.filters;
      } else {
        newfilters = this.filters.filter(
          (item) => item.label != "Origin Departament"
        );
      }
      return newfilters;
    },
    // Is attention for Logistic and Human Talent
    attentionSection() {
      return this.$route.meta.attentionSection ?? 0;
    },
    //For Logistic and Human Talent tabs
    tabStatus() {
      return this.$route.meta.status ?? 0;
    },
    // All claims for Management
    allClaims() {
      return this.$route.meta.allClaims ?? 0;
    },
  },
  methods: {
    openCreateEmployeeClaim(claim) {
      this.draftId = claim.claim_request_id;
      this.showModalCreateEmployeeClaim = true;
    },
    ...mapActions("EmployeeClaimsStore", ["A_UPDATE_TABLE_EMPLOYEE_CLAIMS"]),
    closeModalAttendClaim() {
      this.showModalAttendClaim = false;
      // this.$refs.refGridEmployeeClaims.refresh();
    },
    openAttendClaimModal(claimId) {
      this.claimId = claimId;
      this.showModalAttendClaim = true;
    },
    refreshTable() {
      this.$refs.refGridEmployeeClaims.refresh();
    },

    async myProvider(ctx) {
      let sortby = 10;
      let sortDirection = "desc";
      if (ctx.sortBy === "send_date") {
        sortby = 10;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "for_departament") {
        sortby = 8;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }
      try {
        let params = {
          name_text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          status: this.filters[2].model,
          departament: this.filters[3].model,
          originModule: this.filters[4].model ?? null,
          orderby: sortby,
          order: sortDirection,
          perPage: ctx.perPage,
          page: ctx.currentPage,
          moduleId: this.moduleId,
          attentionSection: this.attentionSection,
          tabStatus: this.tabStatus,
          roleId: this.currentUser.role_id,
          userId: this.currentUser.user_id,
          allClaims: this.allClaims,
          myClaims: 0,
        };

        const { status, data } = await EmployeeClaimsService.getEmployeeClaims(
          params
        );
        if (status == 200) {
          this.startPage = data.from;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;
          this.endPage = data.last_page;
          this.toPage = data.to;
          this.dataProvider = data.data || [];
          return this.dataProvider;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        if (!this.allClaims && !this.tabStatus) {
          await EmployeeClaimsService.updateEmployeeClaimView({
            userId: this.currentUser.user_id,
          });
        }
      }
    },
    colorStatus(type) {
      let selected;
      switch (type) {
        case 1:
          selected = ["Draft", "bg-light-secondary"];
          break;
        case 2:
          selected = ["Pending", "bg-light-warning"];
          break;
        case 3:
          selected = ["In Revision", "bg-light-primary"];
          break;
        case 4:
          selected = ["Resolved", "bg-light-success"];
          break;
        case 5:
          selected = ["Rejected", "bg-light-danger"];
          break;
      }
      return selected;
    },
    originModuleName(module) {
      let selected;
      switch (module) {
        case "CREDIT EXPERTS":
          selected = "CREDIT EXPERTS - CUSTOMER SERVICE REGULAR";
          break;
        case "CUSTOMER SERVICE DIGITAL":
          selected = `CREDIT EXPERTS - ${module}`;
          break;
        case "CUSTOMER SERVICE REGULAR":
          selected = `CREDIT EXPERTS - ${module}`;
          break;
        case "FINANCIAL":
          selected = `CREDIT EXPERTS - ${module}`;
          break;
        case "CONNECTION":
          selected = `CREDIT EXPERTS - ${module}`;
          break;
        case "SALES":
          selected = `CREDIT EXPERTS - ${module}`;
          break;
        case "RRHH":
          selected = "HUMANT TALENT";
          break;
        default:
          selected = module;
          break;
      }
      return selected;
    },
    async allModules() {
      const { data } = await EmployeeClaimsService.getModules();
      let newData = data;
      let idToNameMap = {
        17: "HUMANT TALENT",
        20: "CREDIT EXPERTS - CONNECTION",
        21: "CREDIT EXPERTS - CUSTOMER SERVICE REGULAR",
        22: "CREDIT EXPERTS - CUSTOMER SERVICE DIGITAL",
        23: "CREDIT EXPERTS - FINANCIAL",
        26: "CREDIT EXPERTS - SALES",
      };

      let convertedArray = newData.map(function (item) {
        if (idToNameMap.hasOwnProperty(item.id)) {
          item.name = idToNameMap[item.id];
        }
        return item;
      });
      convertedArray.unshift({
        name: "All",
        id: null,
      });
      this.filters[4].options = convertedArray;
    },
    capitalizeNames(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    openTracking(id, attentionSection) {
      this.claim.id = id;
      this.claim.attentionSection = attentionSection;
      this.claim.status = true;
    },
    returnClass(item) {
      const color = this.isDarkSkin ? "table-light-08 " : "table-dark-08 ";

      if (item !== null) {
        if (
          item.view === 1 &&
          [4, 5].includes(item.status) &&
          !this.allClaims
        ) {
          return `${color}font-size-medium-4`;
        }
      }
    },
    openViewClaims(item) {
      this.id_claim = item.claim_request_id;
      this.openModalViewClient = true;
    },
    changeStatusItem() {
      try {
        window.socket.unsubscribe("channel-chat");
        window.socket.subscribe("channel-chat");
        window.socket.bind("update-claim-status-general", async (response) => {
          const { status, claimer_id, id } = response;
          if (this.currentUser.user_id == claimer_id) {
            for (let i = 0; i < this.dataProvider.length; i++) {
              if (this.dataProvider[i].claim_request_id === parseInt(id)) {
                this.dataProvider[i].status = parseInt(status);
                break;
              }
            }
          }
        });
      } catch (error) {}
    },
    openSurvey(item) {
      this.claim.id = item.claim_request_id;
      this.openSurveyEmojiCard = true;
    },
    closeSurveyEmojiCard(item) {
      this.openSurveyEmojiCard = false;
      if (item) {
        for (let i = 0; i < this.dataProvider.length; i++) {
          if (this.dataProvider[i].claim_request_id === parseInt(item)) {
            this.dataProvider[i].survey_rating = 1;
            break;
          }
        }
      }
    },
  },
  watch: {
    employeeClaimsStore(newValue) {
      if (newValue) {
        this.$refs.refGridEmployeeClaims.refresh();
        setTimeout(() => {
          this.A_UPDATE_TABLE_EMPLOYEE_CLAIMS(false);
        }, 900);
      }
    },
  },
  async created() {
    await this.allModules();
    this.changeStatusItem();
  },
  destroyed() {
    window.socket.unsubscribe("channel-chat");
  },
};
</script>
<style>
.table-light-08 {
  background-color: rgba(82, 55, 4, 0.5) !important;
}

.table-dark-08 {
  background-color: rgb(250, 241, 226) !important;
}
</style>
  